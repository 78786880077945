import  React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import AnchorLink from 'react-anchor-link-smooth-scroll' 
import { Layout } from '../components/Layout'
import { components } from '../slices'
import MapMarker from "../images/map-marker.svg"
import { 
  FaUsers,FaTwitter,FaGlobe,FaFacebookSquare,FaInstagram,FaExternalLinkSquareAlt,FaComment,FaGraduationCap
} from 'react-icons/fa'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker
} from "@react-google-maps/api";
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { StaticImage,GatsbyImage } from 'gatsby-plugin-image'


const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = "AIzaSyCJxdplNccL1mEK13hKt-l3Ody6lZgoWDI"; // PUT GMAP API KEY HERE
const defaultLocation = { lat: 60.219386, lng: -101.645961 };

const SchoolTemplate = ({ data }) => {
  if (!data) return null

  const schoolContent = data.prismicSchool
  const school = schoolContent.data || {}

  const { lang, type, url } = schoolContent
  const alternateLanguages = schoolContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  function socialIcon(type){
    switch(type?.toLowerCase()) {
      case 'instagram':
        return (<FaInstagram/>);
      case 'twitter':
        return (<FaTwitter/>);
      case 'website':
        return (<FaGlobe/>);
      case 'facebook':
        return (<FaFacebookSquare/>);
      default:
        return (<FaExternalLinkSquareAlt/>);
    }
  }

  const [map, setMap] = useState(null);  
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
    }
  }, [map]);


  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="School">
        <section className="SchoolHero">
          <div className="Container">
            <div className="flex-wrap">
              <div className="copy">
                <h1>{school.title}</h1>
                <p>{school.hero_copy}</p>
                <div className="social-icons">
                  {school.social_links.map((item,index) => (
                    <PrismicLink href={item.link?.url} key={index} className="social-icon"> 
                      {socialIcon(item.social_type)}
                      {item.label}
                    </PrismicLink>
                  ))}
                </div>
              </div>
              <div className="image">
                {school.featured_image?.gatsbyImageData &&
                  <GatsbyImage
                    image={school.featured_image?.gatsbyImageData}
                    alt={school.featured_image?.alt || ""}
                  />
                }
              </div>
            </div>
          </div>
        </section>
        <section className="Map">
          <LoadScript googleMapsApiKey={key} libraries={lib} mapIds={idG}>
            <GoogleMap
              center={defaultLocation}
              zoom={3.75}
              onLoad={onLoad}
              options={{ mapId: "614f2b924c037cf7" }}
              mapContainerStyle={{ height: "600px"}}
              disableDefaultUI={true}
              fullscreenControl={false}
            >
              { /* Child components, such as markers, info windows, etc. */ }
                <Marker
                  key={1}
                  position={{lat: school.geopoints?.latitude, lng: school.geopoints?.longitude}}
                  icon= {MapMarker}
                  className="school-marker"
                >
                 
                  <InfoWindow>
                    <div><strong>{school.marker_school}</strong> 
                      <br/>{school.marker_name}<br/>
                      <PrismicLink href={school.marker_link?.url} style={{textDecoration: "underline"}}>
                        {school.marker_link_label}
                      </PrismicLink>
                    </div>
                  </InfoWindow>

                </Marker>

              ))}
              <div className="floating-window">
                <PrismicRichText field={school.location_description?.richText}/>
              </div>
            </GoogleMap>
          </LoadScript>
        </section>
        <section className="Details">
          <div className="Container">
            <div className="flex-wrap">
              <div className="image">
                {school.details_image?.gatsbyImageData &&
                  <GatsbyImage
                    image={school.details_image?.gatsbyImageData}
                    alt={school.details_image?.alt || ""}
                    className="details-image"
                  />
                }
              </div>
              <div className="copy">
                <h2>{school.details_title}</h2>
                <div className="icon"><FaUsers/><PrismicRichText field={school.population?.richText}/></div>
                <div className="icon"><FaComment/><FaComment/><PrismicRichText field={school.languages?.richText}/></div>
                <div className="icon"><FaGraduationCap/><PrismicRichText field={school.grades?.richText}/></div>
              </div>
            </div>
          </div>
        </section>
        <section className="Description">
          <div className="Container">
            <div className="flex-wrap">
              <div className="copy">
                <PrismicRichText field={school.about_copy?.richText}/>
              </div>
              <div className="image">
                {school.about_image?.gatsbyImageData &&
                  <GatsbyImage
                    image={school.about_image?.gatsbyImageData}
                    alt={school.about_image?.alt || ""}
                    className="description-image"
                  />
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Query($id: String, $lang: String) {
    prismicSchool(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
      }
      data {
        title 
        hero_copy 
        featured_image {
          gatsbyImageData
          alt
        }
        social_links {
          social_type 
          link {
            url 
          }
          label
        }
        marker_name
        marker_school
        geopoints {
          latitude
          longitude
        }
        marker_link {
          url
        }
        marker_link_label
        location_description {
          richText
        }
        details_image {
          gatsbyImageData(width: 478)
          alt
        }
        details_title
        population {
          richText
        }
        languages {
          richText
        }
        grades {
          richText
        }
        about_image {
          gatsbyImageData(width: 581)
          alt 
        }
        about_copy {
          richText
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(SchoolTemplate)
