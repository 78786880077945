import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import AnchorLink from 'react-anchor-link-smooth-scroll' 
import { Layout } from '../components/Layout'
import { components } from '../slices'
import UpArrow from '../images/up-arrow.svg'
const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}
  const source = pageContent.uid?.split('wellbeing-')[1]
  

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Page" style={{background: page.background_color}}>
        <div className="Page_Title Container">
          <h1 style={{textAlign: page.header_align}}>{page.display_title?.text}</h1>
        </div>
        <SliceZone slices={page.body} components={components} context={{lang: lang, source: source, type: type}} />
        <div  className="page___header-link"><AnchorLink href="#header"><img src={UpArrow} alt="" className="up-arrow"/></AnchorLink></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        display_title{
          text
        }
        header_align
        background_color
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyTextInfo
          ...PageDataBodyContact
          ...PageDataBodyPartners
          ...PageDataBodyProgramPartners
          ...PageDataBodyProgramSupporters
          ...PageDataBodyGetInvolved
          ...PageDataBodyLearnMoreHero
          ...PageDataBodyPageNav
          ...PageDataBodyTextAndImage
          ...PageDataBodyWellbeingHero
          ...PageDataBodyTextSection
          ...PageDataBodyAnotherFramework
          ...PageDataBodyNewsArchive
          ...PageDataBodyFuturepathwaysHero
          ...PageDataBodyPublications
          ...PageDataBodyFaqSection
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)
