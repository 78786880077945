import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const ImageStats = ({ slice }) => {
  return (
    <section
      className="ImageStats ImageText"
      id={slice.primary.anchor_id || ''}
    >
      <div className="Container Slices_Container ImageText__flex">
        <div
          className={slice.primary.image_side ? 'image_left' : 'image_right'}
        >
          {isFilled.image(slice.primary.image) && (
            <div className="image_container">
              <GatsbyImage
                image={slice.primary.image.gatsbyImageData}
                alt={slice.primary.image.alt || ''}
              />
            </div>
          )}
        </div>
        <div className="header_and_text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
          <div className="stats-grid">
          {slice.items.map((stat, index) => (
            <div key={index}>
                <div className="major-stat">{stat.major_stat}</div>
                <div className="minor-stat">{stat.minor_stat}</div>
            </div>
          ))}
        </div></div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyImageStats on PrismicUpdatedPageDataBodyImageStats {
    id
    primary {
      anchor_id
      image_side
      header {
        richText
      }
      text {
        richText
      }
      image {
        gatsbyImageData
        url
        alt
      }
    }
    items {
      major_stat
      minor_stat
    }
  }
`
