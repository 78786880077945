import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const CardsImages = ({ slice }) => {

  return (
    <section className="CardsImages" id={slice.primary.anchor_id || ""}>
    <div className="Slices_Container Container">
      <div className="Cards__text">
        {isFilled.richText(slice.primary.header.richText) &&
          <PrismicRichText field={slice.primary.header.richText}/>
        }
        {isFilled.richText(slice.primary.text.richText) &&
          <PrismicRichText field={slice.primary.text.richText}/>
        }
      </div>
      <div className="CardsImages__Container">
        {slice.items.map((item,index) => (
          <div key={index} className="card"> 
            <div>
              {isFilled.image(item.image) && 
                <GatsbyImage image={item.image.gatsbyImageData} alt={item.image.alt || ""} className="card_image"/>
              }
            </div>
            <div className="card_text">
            {isFilled.richText(item.header.richText) &&
              <PrismicRichText field={item.header.richText}/>
            }
            {isFilled.richText(item.text.richText) &&
              <PrismicRichText field={item.text.richText}/>
            }
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyCardsImages on PrismicUpdatedPageDataBodyCardsImages {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      image {
        gatsbyImageData
        url
        alt
      }
      header {
        richText
      }
      text {
        richText
      }
    }
  }
`