import { TextInfo } from './TextInfo'

import { Programs } from './Homepage/Programs'
import { About } from './Homepage/About'
import { Schools } from './Homepage/Schools'
import { Support } from './Homepage/Support'
import { Partners } from './Homepage/Partners'
import { GetInvolved } from './Homepage/GetInvolved'
import { NewsSection } from './Homepage/NewsSection'
import { Principles } from './Homepage/Principles'
import { Map } from './Homepage/Map'

import { Contact } from './Contact'
import { ProgramPartners } from './ProgramPartners'
import { ProgramSupporters } from './ProgramSupporters'
import { NewsArchive } from './NewsArchive'

import { LearnMoreHero } from './LearnMore/LearnMoreHero'
import { TextAndImage } from './LearnMore/TextAndImage'
import { WellbeingHero } from './Wellbeing/WellbeingHero'
import { PageNav } from './Wellbeing/PageNav'
import { TextSection } from './TextSection'

import { FuturepathwaysHero } from './Futurepathways/FuturepathwaysHero'
import { Publications } from './Futurepathways/Publications'

import { FaqSection } from './FaqSection'
import { AnotherFramework } from './Wellbeing/AnotherFramework'
import { CardsIcons } from './CardsIcons'
import { CardsImages } from './CardsImages'
import { Cta } from './Cta'
import { CtaQuote } from './CtaQuote'
import { Hero } from './Hero'
import { HeroThermometer } from './HeroThermometer'
import { ImageStats } from './ImageStats'
import { ImageText } from './ImageText'
import { StickyNav } from './StickyNav'
import { TextButtons } from './TextButtons'
import { List } from './List'
import { Newsletter } from './Newsletter'


export const components = {
  text_info: TextInfo,

  programs: Programs,
  about: About,
  schools: Schools,
  map: Map,
  support: Support,
  partners: Partners,
  get_involved: GetInvolved,
  news_section: NewsSection,
  principles: Principles,

  contact: Contact,
  program_partners: ProgramPartners,
  program_supporters: ProgramSupporters,
  news_archive: NewsArchive,

  learn_more_hero: LearnMoreHero,
  text_and_image: TextAndImage,
  wellbeing_hero: WellbeingHero,
  another_framework: AnotherFramework,
  page_nav: PageNav,
  text_section: TextSection,

  futurepathways_hero: FuturepathwaysHero,
  publications: Publications,
  
  faq_section: FaqSection,

  cards_icons: CardsIcons,
  cards_images: CardsImages,
  cta: Cta,
  cta_quote: CtaQuote,
  hero: Hero,
  hero_thermometer: HeroThermometer,
  image_stats: ImageStats,
  image_text: ImageText,
  sticky_nav: StickyNav,
  text_buttons: TextButtons,
  list: List,
  newsletter: Newsletter,
}
