import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { BottomMenu } from './BottomMenu'


export const Layout = ({ children, topMenu, bottomMenu, activeDocMeta }) => {
  const isBrowser = () => typeof window !== "undefined"
  if(isBrowser()){
    window["_lang"] = activeDocMeta.lang.slice(0,2);
  }

  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik&display=swap" rel="stylesheet"/>
        <link rel="stylesheet" href="https://use.typekit.net/zgl8jcz.css"/> 
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script>
          {`
            (function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
              .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
              :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
              j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
              t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
              for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
              })(window,document,'script','FundraiseUp','ANFTSWCF');
        `}
        </script>
        
      </Helmet>
      <TopMenu topMenu={topMenu} activeDocMeta={activeDocMeta} />
      <main>{children}</main>
      <BottomMenu bottomMenu={bottomMenu} activeDocMeta={activeDocMeta}/>
    </>
  )
}
