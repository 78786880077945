import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink  } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from "react-slick";

import Harmful from '../images/harmful.png'
import Helpful from '../images/helpful.png'
import { Layout } from '../components/Layout'

const WellbeingSubpageTemplate = ({ data }) => {
  if (!data) return null

  const [source, setSource] = React.useState("")
  const [isAlternate, setIsAlternate] = React.useState(false);

  React.useEffect(()=> {
    if(document !== undefined) {
      const tSource = new URLSearchParams(document?.location.search).get('framework')
      setSource(tSource)

      const alt = tSource == 'tsista' ? true : false;
      setIsAlternate(alt)
    } else {
      setSource(null)
    }
  },[])

  const wellbeingContent = data.prismicWellbeingSubpage
  const wb = wellbeingContent.data || {}
  const id = wellbeingContent.id || {}

  const wellbeingPages = data.allPrismicWellbeingSubpage || {}

  const { lang, type, url } = wellbeingContent
  const alternateLanguages = wellbeingContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  const Background = wb.research_background_image?.url || null

  const settings = {
    dots: true,
    infinite:true,
    speed: 500,
    prevArrow: <></>,
    nextArrow: <></>,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="WellbeingSubpage">
        <section className="wellbeing-hero" style={{background: wb.hero_background_color}}>
          <div className="Container">
            <div className="hero___wrap">
              {
                isAlternate ?
                <GatsbyImage
                  image={wb.alt_image?.gatsbyImageData}
                  alt={wb.alt_image.alt || ""}
                  className="hero___image"
                />
                :
                <GatsbyImage
                  image={wb.hero_image?.gatsbyImageData}
                  alt={wb.hero_image.alt || ""}
                  className="hero___image"
                />
              }
             
              
              <div className="hero___copy">
                <h1>{wb.display_title}</h1>
                <PrismicRichText field={wb.hero_copy?.richText}/>
              </div>
            </div>
          </div>
        </section>
        <section className="wellbeing-intro">
          <div className="Container">
            <div className="intro___wrap">
              <div className="grey-box harmful">
                <img src={Harmful} alt=""/>
                <PrismicRichText field={wb.harmful_text?.richText} className="intro___copy"/>
              </div>
              <div className="grey-box helpful">
                <img src={Helpful} alt=""/>
                <PrismicRichText field={wb.helpful_text?.richText} className="intro___copy"/>
              </div>
            </div>
            <div className="intro___examples">
              <PrismicRichText field={wb.examples_text?.richText}/>
            </div>  
          </div>
        </section>
        <section className="wellbeing-second" style={{background: "url(" + Background + ")"}}>
          <div className="Container">
            <div className="second___wrap">
              <h2>{wb.research_header}</h2>
              <Slider {...settings}>
                {wb.research?.map((item,index) => (
                  <div key={id + index} className="second___slide">
                    <PrismicRichText field={item.research_text?.richText}/>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
        <section className="wellbeing-third">
          <div className="Container">
            <div className="third___wrap">
              <h2>{wb.actions_header}</h2>
            </div>
            <div className="third___grid">
              {wb.actions?.map((item,index) => (
                <div key={id + index} className="third___grid-item">
                  <h4>{(index + 1) > 9 ? (index + 1) : "0" + (index + 1)}</h4>
                  <p>{item.actions_text}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="wellbeing-cta">
          <div className="Container">
            <div className="cta___wrap">
              <h2>{wb.nav_header}</h2>
              <div className="cta___nav">
                {wellbeingPages.nodes?.map((page,index) => (
                  <PrismicLink className="cta___link" href={isAlternate ? page.url+`?framework=${source}` : page.url || '#'} key={page.id}>
                    {isAlternate ?
                      <GatsbyImage 
                        image={page.data?.alt_image?.gatsbyImageData} 
                        alt="" 
                        className="cta___link-image cta___link-image-alt"
                      />
                      :
                      <GatsbyImage 
                        image={page.data?.hero_image?.gatsbyImageData} 
                        alt="" 
                        className="cta___link-image"
                      />
                    }
                    
                    <p>{page.data?.display_title}</p>
                  </PrismicLink>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="acknowledgements">
          <div className="Container">
            <div className="acknowledgements___wrap">
              <PrismicRichText field={wb.acknowledgements?.richText}  className="acknowledgements___copy"/>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query wellbeingQuery($id: String, $lang: String) {
    prismicWellbeingSubpage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data{
        display_title
        hero_background_color
        hero_copy{
          richText
        }
        hero_image{
          gatsbyImageData(
            width: 300, imgixParams: {fit: "contain"} 
          )
          alt
        }
        alt_image{
          gatsbyImageData(
            width: 300, imgixParams: {fit: "contain"} 
          )
          alt
        }
        harmful_text{
          richText
        }
        helpful_text{
          richText
        }
        examples_text{
          richText
        }
        research_header
        research_background_image{
          url
        }
        research{
          research_text{
            richText
          }
        }
        actions_header
        actions{
          actions_text
        }
        nav_header
        acknowledgements{
          richText
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
    allPrismicWellbeingSubpage(filter: {lang: {eq: $lang}}) {
      nodes{
        url
        id
        data{
          display_title
          hero_image{
            gatsbyImageData(
              width: 200, imgixParams: {fit: "none"} 
            )
          }
          alt_image{
            gatsbyImageData(
              width: 200, imgixParams: {fit: "none"} 
            )
          }
        }
      }
    }
  }
`

export default withPrismicPreview(WellbeingSubpageTemplate)
